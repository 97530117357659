import { Grid, makeStyles } from '@material-ui/core';
import predisposition from '../../../../content/careers/pages/frontend/predisposition.json';
import MetroSans from '../../../../fonts/metro-sans/metroSans';
import colors from '../../../../styles/colors';
import { defaultSectionWidth, regularText, smallText } from '../../../../styles/dimensions';

interface Props {
	content: typeof predisposition;
}

const Predisposition = ({ content }: Props) => {
	const classes = useClasses();

	return (
		<section className={classes.section}>
			<Grid container>
				<Grid item sm={1} md={2}></Grid>

				<Grid item xs={12} sm={10} md={6}>
					{content.sections.map((section, key) => (
						<div key={key} data-scroll data-scroll-speed="3">
							<h2 className={classes.header} data-scroll>
								{section.title}
							</h2>
							{section.predispositions.map((predisposition, i) => (
								<p key={i} className={classes.predisposition}>
									{predisposition}
								</p>
							))}
						</div>
					))}
				</Grid>

				<Grid item sm={1} md={4}></Grid>
			</Grid>
		</section>
	);
};

export default Predisposition;

const useClasses = makeStyles({
	section: {
		maxWidth: defaultSectionWidth,
		margin: '0 auto',
		paddingBottom: '9em',
		'@media (max-width: 599px)': {
			paddingLeft: '1em',
			paddingRight: '1em',
			paddingBottom: '7em',
		},
		'@media (max-width: 499px)': {
			paddingLeft: '1em',
			paddingRight: '1em',
			paddingBottom: '5em',
		},
	},
	header: {
		marginTop: '0em',
		marginBottom: '0.8em',
		'@media (max-width: 599px)': {
			fontSize: regularText.fontSize,
			lineHeight: regularText.lineHeight,
			marginTop: '3em',
			marginBottom: '1.2em',
		},
	},
	predisposition: {
		fontFamily: MetroSans.book,
		fontSize: smallText.fontSize,
		lineHeight: smallText.lineHeight,
		color: colors.textGrey,
		borderBottom: `1px solid ${colors.dividerGray}`,
		padding: '0.25em 0',
		margin: '1.5em 0',
	},
});
