import { Grid, makeStyles } from '@material-ui/core';
import { useRef, useEffect } from 'react';
import MetroSans from '../../../../fonts/metro-sans/metroSans';
import RigrafExpanded from '../../../../fonts/rigraf/expanded/rigrafExpanded';
import colors from '../../../../styles/colors';
import { defaultSectionWidth, smallSubtitle, smallText } from '../../../../styles/dimensions';
import intro from '../../../../content/careers/pages/frontend/intro.json';
import AnimatedImage from '../../../../elements/animatedImage';
import { splitData } from '../../../../helpers/splitData';

interface Props {
	content: typeof intro;
	bannerImage: JSX.Element;
	bannerSrc: string;
}

const Intro = (props: Props) => {
	const { content, bannerImage, bannerSrc } = props;

	const classes = useClasses();

	const gridItemRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		splitData(0.5, 0.02);

		setTimeout(() => {
			gridItemRef.current.style.opacity = '1';
		}, 1);
	}, []);

	return (
		<section className={classes.section}>
			<Grid container>
				<Grid item sm={1}></Grid>

				<Grid item xs={12} sm={10} className={classes.gridItem} ref={gridItemRef}>
					<h1 className={classes.header} data-scroll data-scroll-speed="3" data-splitting>
						{content.header.title}
					</h1>
					<p className={classes.headerDescription} data-scroll data-scroll-speed="3" data-splitting>
						{content.header.description}
					</p>

					<div className={classes.detailsContainer} data-scroll data-scroll-speed="3">
						{content.header.details.map((detail, key) => (
							<div key={key} className={classes.detail}>
								<h6 className={classes.detailHeader} data-splitting>
									{detail.title}
								</h6>
								<span className={classes.detailsDescription} data-splitting>
									{detail.description}
								</span>
							</div>
						))}
					</div>
				</Grid>

				<Grid item sm={1}></Grid>

				<Grid item xs={12} data-scroll data-scroll-speed="2">
					<AnimatedImage image={bannerSrc} className={classes.animatedImage} />

					{bannerImage}
				</Grid>
			</Grid>
		</section>
	);
};

export default Intro;

const useClasses = makeStyles({
	section: {
		maxWidth: defaultSectionWidth,
		margin: '0 auto',
		paddingTop: 'calc(7.625em + 2px)',
		'@media (min-width: 600px) and (min-height: 900px)': {
			paddingTop: 'calc(7.625em + 2px + 5vh)',
		},
		'@media (max-width: 599px)': {
			paddingLeft: '1em',
			paddingRight: '1em',
			paddingTop: 'calc(3.75em + 0.5em)',
		},
	},
	header: {
		marginTop: '1.6428571428571428em',
		marginBottom: '0.2857142857142857em',
		'@media (max-width: 599px)': {
			fontFamily: RigrafExpanded.semiBold,
			fontWeight: 'normal',
			fontSize: smallSubtitle.fontSize,
			lineHeight: smallSubtitle.lineHeight,
			marginTop: '1.25em',
			marginBottom: '0.625em',
		},
		'& span': {
			fontFamily: 'inherit',
			fontSize: 'inherit',
			lineHeight: 'inherit',
		},
	},
	headerDescription: {
		fontFamily: MetroSans.book,
		color: colors.textGrey,
		marginTop: '0.8em',
		marginBottom: '1.6em',
		maxWidth: 'calc(58.33333333% * 12 / 10)',
		'@media (max-width: 599px)': {
			maxWidth: 'unset',
			fontSize: smallText.fontSize,
			lineHeight: smallText.lineHeight,
			marginTop: '1.25em',
			marginBottom: '2.5em',
		},
		'& span': {
			fontFamily: 'inherit',
			fontSize: 'inherit',
			lineHeight: 'inherit',
		},
	},
	detailsContainer: {
		display: 'flex',
		flexDirection: 'row',
		'@media (max-width: 599px)': {
			display: 'none',
		},
	},
	detail: {
		display: 'flex',
		flexDirection: 'column',
		marginRight: '6.25em',
	},
	detailHeader: {
		marginTop: 0,
		marginBottom: '0.25em',
		'& span': {
			fontFamily: 'inherit',
			fontSize: 'inherit',
			lineHeight: 'inherit',
		},
	},
	detailsDescription: {
		fontFamily: MetroSans.book,
		fontSize: smallText.fontSize,
		lineHeight: smallText.lineHeight,
		margin: '0.25em 0',
		color: colors.textGrey,
		'& span': {
			fontFamily: 'inherit',
			fontSize: 'inherit',
			lineHeight: 'inherit',
		},
	},
	animatedImage: {
		maxWidth: '100%',
		height: 'auto !important',
		marginTop: '5.75em',
		'@media (max-width: 1199px)': {
			display: 'none !important',
		},
	},
	gridItem: {
		opacity: 0,
	},
});
