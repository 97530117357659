import { makeStyles } from '@material-ui/core';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../../components/layout';
import Contact from '../../components/sections/careers/pages/contact';
import Intro from '../../components/sections/careers/pages/intro';
import Predisposition from '../../components/sections/careers/pages/predisposition';
import Provide from '../../components/sections/careers/pages/provide';
import SEO from '../../components/seo';
import { descriptions, titles } from '../../consts/seo';
import content from '../../content/careers/pages/recruiter/recruiter.json';
// @ts-ignore
import bannerSrc from '../../images/careers/frontend/job_openings_photo_1.jpg';

export default function recruiter() {
	const classes = useClasses();

	const bannerImage = (
		<StaticImage
			src="../../images/careers/frontend/job_openings_photo_1.jpg"
			alt=""
			className={classes.image}
			layout="constrained"
			loading="eager"
			placeholder="blurred"
			aria-hidden="true"
		/>
	);

	return (
		<Layout>
			<SEO title={titles.careers.recruiter} description={descriptions.careers.recruiter} />

			<Intro content={content.intro} bannerImage={bannerImage} bannerSrc={bannerSrc} />
			<Predisposition content={content.predisposition} />
			<Provide />
			<Contact />
		</Layout>
	);
}

const useClasses = makeStyles({
	image: {
		'@media (min-width: 600px)': {
			marginTop: '5.75em',
		},
		'@media (min-width: 1200px)': {
			display: 'none !important',
		},
	},
});
