import { Grid, makeStyles } from '@material-ui/core';
import content from '../../../../content/careers/pages/provide.json';
import MetroSans from '../../../../fonts/metro-sans/metroSans';
import colors from '../../../../styles/colors';
// @ts-ignore
import backgroundGradient from '../../../../images/careers/background_gradient.jpg';
import { defaultSectionWidth, regularText, smallText } from '../../../../styles/dimensions';

interface Props {
	quoteShown?: boolean;
}

const Provide = ({ quoteShown }: Props) => {
	const classes = useClasses({ quoteShown });

	return (
		<section className={classes.section}>
			<Grid container className={classes.container}>
				<Grid item sm={1} md={2}></Grid>

				<Grid item xs={12} sm={10} md={6}>
					{content.sections.map((section, key) => (
						<div key={key} data-scroll data-scroll-speed="2">
							<h2 className={classes.header}>{section.title}</h2>
							{section.provide.map((item, i) => (
								<p key={i} className={classes.provide}>
									{item}
								</p>
							))}
						</div>
					))}

					<div className={classes.bottomHeaderContainer} data-scroll data-scroll-speed="2">
						<h3 className={classes.headerTitle}>{content.apply.title}</h3>
						<p className={classes.headerParagraph}>{content.apply.paragraph}</p>
					</div>
				</Grid>

				<Grid item sm={2} md={3}></Grid>
			</Grid>
		</section>
	);
};

export default Provide;

const useClasses = makeStyles({
	section: {
		backgroundImage: `url(${backgroundGradient})`,
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		paddingTop: '10em',
		paddingBottom: '10em',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'@media (max-width: 959px)': {
			paddingTop: (props: Props) => (props.quoteShown ? '12em' : '10em'),
		},
		'@media (max-width: 599px)': {
			paddingTop: (props: Props) => (props.quoteShown ? '16.25em' : '6em'),
			paddingBottom: '4em',
			paddingLeft: '1em',
			paddingRight: '1em',
		},
	},
	container: {
		maxWidth: defaultSectionWidth,
		margin: '0 auto',
	},
	header: {
		marginTop: 0,
		marginBottom: '0.8em',
		whiteSpace: 'pre-wrap',
		color: colors.white,
		'@media (max-width: 599px)': {
			fontSize: regularText.fontSize,
			lineHeight: regularText.lineHeight,
			marginBottom: '1.2em',
		},
	},
	provide: {
		maxWidth: defaultSectionWidth,
		fontFamily: MetroSans.book,
		fontSize: smallText.fontSize,
		lineHeight: smallText.lineHeight,
		color: colors.careersJobGray,
		borderBottom: `1px solid ${colors.careersJobGray}`,
		padding: '0.25em 0',
		margin: '1.5em 0',
	},
	innerContainer: {
		width: defaultSectionWidth,
	},
	bottomHeaderContainer: {
		marginTop: '3em',
		marginBottom: '5.75em',
		'@media (max-width: 599px)': {
			marginBottom: '3em',
		},
	},
	headerTitle: {
		marginTop: 0,
		marginBottom: '0.625em',
		whiteSpace: 'pre-wrap',
		color: colors.white,
		'@media (max-width: 599px)': {
			fontSize: regularText.fontSize,
			lineHeight: regularText.lineHeight,
			marginBottom: '1em',
		},
	},
	headerParagraph: {
		fontFamily: MetroSans.book,
		fontSize: smallText.fontSize,
		lineHeight: smallText.lineHeight,
		color: colors.white,
		marginTop: '1em',
		marginBottom: 0,
	},
});
